import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ILogin } from 'app/shared/models/login';

const Auth = createActionGroup({
  source: 'Auth',
  events: {
    signIn: props<{ credentials: ILogin }>(),
    signOut: emptyProps(),
    forgotPassword: props<{ email: string; user: string }>(),
    changePassword: props<{ currentPassword: string; newPassword: string; repeatPassword: string }>(),
    resetState: emptyProps(),
    startWatching: emptyProps(),
    stopWatching: emptyProps(),
    lockSession: emptyProps(),
    setPhoneNumber: props<{ phoneNumber: string }>()
  }
});

const AuthApi = createActionGroup({
  source: 'Auth/API',
  events: {
    initialAuth: emptyProps(),
    signInSuccess: props<{ accessToken: string; decodedToken: any; rememberMe: boolean }>(),
    signInWithTokenSuccess: props<{ accessToken: string; decodedToken: any }>(),
    signInError: props<{ errorCode: string; errorMessage: string }>(),
    forgotPasswordSuccess: emptyProps(),
    changePasswordSuccess: emptyProps(),
    resetPassword: props<{ password: string }>(),
    resetPasswordSuccess: emptyProps()
  }
});

export const AuthActions = {
  ...Auth,
  ...AuthApi
};
